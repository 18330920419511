import React from 'react'

import Layout from '../components/Layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Nie znaleziono" />
    <h1>Błąd 404: Nie znaleziono strony</h1>
  </Layout>
)

export default NotFoundPage
